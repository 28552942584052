import { Component, OnInit } from '@angular/core';
import { Logo, Category } from './main-search.model';
import { Router } from '@angular/router';
import { SearchService } from '../../core/search/search-service';
@Component({
  selector: 'app-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss'],
})
export class MainSearchComponent implements OnInit {
  logo = Logo;
  category = Category;
  categoryType = 'house';
  searchQuery = '';
  ipAddress: Object;

  constructor(private searchService: SearchService, private router: Router) {}

  ngOnInit(): void {
    this.getIp();
  }

  homeClick() {
    this.categoryType = 'house';
  }

  flatClick() {
    this.categoryType = 'flat';
  }

  roomClick() {
    this.categoryType = 'room';
  }

  setSearchtype(type: string) {
    let spans = Array.from(document.getElementsByClassName('spanBtn') as HTMLCollectionOf<HTMLElement>)
    spans.forEach(span=> {
      span.style.borderBottom = '0px solid blue';
    })

    this.categoryType = type
    let btn = type[0] + 'Btn'
    let btnS = document.getElementById(btn);
    console.log("btn", btnS)
    // btnS.style.backgroundColor = '#0095ff';
    btnS.style.borderBottom = '2px solid #6dcdff';
  }

  searchQueryCall(query) {
    console.log("query", query);
    this.router.navigate(['search-result'], {
      queryParams: 
      { searchQuery: query, 
        pageNumber: 1, 
        property_type: this.categoryType,
        numberOfRecord:10},
    });
  }
  getIp() {
    this.searchService.getIpCliente().subscribe((data: any) => {
      console.log('data', data);
      this.ipAddress = data.ip ? data.ip : '';
    });
  }

}
