<div id="root">
    <div>
        <div class="full-Screen">
            <div class="container">
                <div class="row">
                    <div class="logo-section col-md-6 col-4"><a href="/"><img src="assets/images/logo.svg" alt="Logo"></a>
                    </div>
                    <div class="menu-section col-md-6 col-8">
                        <a href="/#About">About</a>
                        <a href="/">How it Works</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="Home-Search"><img src="assets/images/logo2.svg" alt="Logo">
                <h1>The Property Search Engine</h1>
                <div class="Home-search-menu">
                    <span id="hBtn" (click)="setSearchtype('house')" class="spanBtn">House</span>
                    <span id="fBtn" (click)="setSearchtype('flat')" class="spanBtn">Flat</span>
                    <span id="rBtn" (click)="setSearchtype('room')" class="spanBtn">Room</span>
                </div>
                <div class="Home-search-section input-group" style="background-color: white; width: 70%;">
       
                        <input placeholder="Search locality, landmark, project or builders"
                         type="search" class="form-control"
                         style="padding: 28px; border-radius: 18px 0px 0px 18px;"
                         (keyup.enter)="searchQueryCall(searchStr.value)"
                          #searchStr>
                            <button style="background-color: #0095ff; border: 1px; color: white; padding: 4px 40px 4px 40px; border-radius: 0px 18px 18px 0px;"
                                (click)="searchQueryCall(searchStr.value)"
                            >
                            Search
                            </button>
                        <!-- </span> -->
                </div>
            </div>
        </div>
    </div>

    <div class="About" id="About">
        <div class="container"><h5>About Us</h5><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,</p><h5>Policy</h5><p>Sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
        </div>
    </div>

    <footer>
        <div class="container">
            <div class="align-items-center row">
                <div class="footer-flex col-md-6 col-sm-5">
                    <h2>
                        <a href="https://furiousfox.co.uk/" target="_blank" 
                        rel="noreferrer" style="color: #fff;">
                            Brought To You By Furious Fox
                        </a>
                        </h2>
                    </div>
                    <div class="d-flex footer-flex justify-content-sm-end col-md-6 col-sm-7">
                        <div class="footer-Link">
                            <a href="/privacypolicy" style="color: #fff;">Privacy Policy</a>
                            <a href="/termsandconditions" style="color: #fff;">Terms and Conditions</a>
                        </div>
                    </div>
                </div>
        </div>
    </footer>
</div>