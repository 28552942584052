import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchService } from 'src/app/core/search/search-service';
import { Property } from './property';

@Component({
  selector: 'app-prop-details',
  templateUrl: './prop-details.component.html',
  styleUrls: ['./prop-details.component.scss', '../search-result/extra.css', 
  '../search-result/search-result.component.scss']
})
export class PropDetailsComponent implements OnInit {

  mainImage: string;
  selectedData: Property;
  searchQuery: string;

  constructor(
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.spinner.show();

    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      // this.route.snapshot.queryParamMap.get('searchQuery') || ''
      let latitude = this.activatedRoute.snapshot.queryParamMap.get('latitude') || '';
      let longtitude = this.activatedRoute.snapshot.queryParamMap.get('longtitude') || '';

      this.searchService.getDetails(id, latitude, longtitude).subscribe(
        (result) => {
        console.log("response!@#", result);  
        this.spinner.hide();
        if (result.status === 'Success') {
          if (result.london_properties_4June) {
            this.selectedData = result.london_properties_4June[0];
            console.log("data", this.selectedData)
            this.mainImage = this.selectedData['images'][0]
          }
        }
      },
      error => {
        this.spinner.hide();
        console.log("some error occured", error);
      },
      );
      
  });
    
    // console.log("id:", id)
    // this.searchService.getDetails(id).subscribe((response) => {
    //   this.spinner.hide();
    //   if (response.status === 'Success') {
    //     if (response.london_properties_4June) {
    //       this.selectedData = response.london_properties_4June[0];
    //       console.log("data", this.selectedData)
    //       this.mainImage = this.selectedData['images'][0]
    //     }
    // }
    //   else {
        
    //     prompt('some error occured')
    //   }
    // });
  }

  changeMainImage(img: string): void {
    this.mainImage = img;
  }

  searchQueryCall(query) {
    this.router.navigate(['search-result'], {
      queryParams: 
      { searchQuery: query,
        pageNumber: 1, 
        numberOfRecord:10,
      },
  });
}

}
