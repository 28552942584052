<header>
    
    <div style="background-color: #003b6a;" class="p-3">

        <div class="container">
            <div class="row">
            <div class="col xs-4 md-6 logo-section">
                <a href="/">
                    <img src="assets/images/logo3.svg" alt="Logo" />
                </a>
            </div>
            <div class="col xs-8 md-6 menu-section menu-color-white">
                <a href="#" style="color: white;">About</a>
                <a to="#" style="color: white;">How it Works</a>
            </div>
            </div>
            
            <div class="Main-Header-section input-group">
                <span class="Home-search-input-icon input-group-text">
                    <span>
                        <!-- <a href="#"><i class="fas fa-search" aria-hidden="true"></i></a> -->
                        <a [routerLink]="['/search-result']"
                           [queryParams]="{searchQuery: searchQuery}">
                           <img src="assets/images/search.svg" alt="">
                        </a>
                    </span>
                </span>
                
                <input placeholder="Manchester" type="search" class="form-control"
                [(ngModel)]="searchQuery"
                style="height: 60px;"
                (keyup.enter)="searchQueryCall(searchStr.value)"
                #searchStr>

                <span class="Main-Header-input-button input-group-text">
                    <select>
                        <option value="house" selected="">House</option>
                        <option value="flat">Flat</option>
                        <option value="room">Room</option>
                    </select>
                    
                </span>
                <button class="btn mr-3"
                        style="width:80px; border-radius:0px 18px 18px 0px; background-color: white;"
                        [routerLink]="['/search-result']"
                        queryParamsHandling="preserve">
                    <i class="fa fa-chevron-left"></i>Back
                </button>
            </div>
    </div>
    </div>
</header>

<div style="background-color: #e5e5e5;">
    <div class="container">
        <div class="align-items-center justify-content-between row">
            <h1 style="font-size: 22px; color: #003b6a;" class="py-3">{{selectedData?.property_title}}</h1>
        </div>
            <div class="align-items-center justify-content-between row">
                <p class="" style="color: #003b6a;">{{selectedData?.address}}</p>
                <br><br>
                <p class="d-flex justify-content-md-end col-md-5 justify-content-sm-start"
                    style="color: #003b6a;">
                    Added on {{selectedData?.listed_on}}
                </p>
        </div>
        <div class="detail-image justify-content-between row">
        <div class="col-md-6 detail-main-image">
            <img src={{mainImage}} 
            id="mainImg"    
            alt="house" />
        </div>
    
        <div class="detail-images col-md-6">
                <img *ngFor="let image of selectedData?.images"  
                src={{image}} alt="House" 
                (click)="changeMainImage(image)"
                [ngStyle]="{'opacity': image === mainImage ?  0.5 : 1}"
                class="smallImages" />
        </div> 
        </div>
        <div class="align-items-center justify-content-between row">
        <div className="col-md-6">
            <h1>£{{selectedData?.price}} </h1>
            <div className="d-flex align-items-center detail-paragrap">
            <p>
                {{selectedData?.bedrooms}} beds |
            </p>
            
            </div>
        </div>

        <div className="col-md-6 section-button">
                <a 
                href="{{selectedData?.data_url}}"
                target='_blank'
                >
                <button class="sec-button">
                    Go to Site <img src="assets/images/gotosite-icon.svg" alt="icon" />
                </button>
                </a>
                <!-- <a href="#">
                <button class="sec-button">
                    Share <img src="assets/images/shareicon.svg" alt="icon" />
                </button>
                </a> -->
        </div>
        </div>
        
    </div>
    
</div>

<div style="background-color: #eef3f8;">
    <div class="pt-4 mb-2 container">
        <h5>Property Description</h5>
        <p>{{selectedData?.property_description}}</p>
        <ul>
            <li *ngFor="let prop of selectedData?.long_description">
                {{prop}}
            </li>
        </ul>
    </div>
</div>



<ngx-spinner size="medium" color="#fff" type="ball-spin-clockwise" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

