<!-- <li class="active">
    <fa-icon [icon]="['fas', 'home']" class="icon-cutom" [ngStyle]="item.customStyle"></fa-icon>
    <div>{{item.label}}</div>
</li> -->


<li class="active" *ngIf='item'>
    <!-- <fa-icon [icon]="['fas', item.iconName]" ></fa-icon>
    <div>{{item.label}}</div> -->
    <img [src]="item.imageURL" class="logo" />
</li>