import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryButonComponent } from './category.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CategoryButonComponent],
  exports: [CategoryButonComponent],
  imports: [FormsModule,
    CommonModule
  ]
})
export class CategoryModule { }
