<header>
    <div style="background-color: #003b6a;" class="p-3">
        <div class="container">
            <div class="row">
                <div class="col xs-4 md-6 logo-section" style="">
                    <a href="/">
                        <img src="assets/images/logo3.svg" alt="Logo" />
                    </a>
                </div>
                <div class="col xs-8 md-6 menu-section menu-color-white">
                    <a href="#" style="color: white;">About</a>
                    <a to="#" style="color: white;">How it Works</a>
                </div>
            </div>
            <!-- <div class="Home-search-section input-group mt-2" style="background-color: white;
                box-shadow:none; height: 42px;">
                <input placeholder="Search locality, landmark, project or builders"
                type="search" 
                class="form-control" 
                style="padding: 18px; border-radius: 18px 0px 0px 18px;"
                #searchStr>

                <button style="background-color: #3c739b; border: 1px; color: white;
                padding: 4px 20px 4px 20px; border-radius: 0px 18px 18px 0px;">
                <a routerLink="search-result" style="color:white;">Search..</a>
                </button>
            </div> -->
                <div class="Main-Header-section input-group">
                <span class="Home-search-input-icon input-group-text">
                    <span>
                    <!-- <a href="#"><i class="fas fa-search" aria-hidden="true"></i></a> -->
                    <a [routerLink]="['/search-result']"
                    [queryParams]="{bedRooms: bedroomsCount,
                                    amenities: keywords,
                                    searchQuery: searchQuery,
                                    property_type: roomOrFlatOrHouse,
                                    numberOfRecord:10,
                                    selectedMinRange: selectedMinRange,
                                    selectedMaxRange: selectedMaxRange,
                                    pageNumber: page}">
                    <img src="assets/images/search.svg" alt="">
                    </a>
                    </span>
                </span>
                <input placeholder="Manchester" type="search" class="form-control"
                [(ngModel)]="searchQuery"
                #searchStr
                (keyup.enter)="searchQueryCall(searchStr.value)"
                style="height: 60px;">
                <span class="Main-Header-input-button input-group-text">
                    <select (change)="propTypeChange($event.target.value)">
                        <option value="house"
                            [selected]="roomOrFlatOrHouse == 'house'">House
                        </option>
                        <option value="flat"
                            [selected]="roomOrFlatOrHouse == 'flat'">Flat
                        </option>
                        <option value="room"
                            [selected]="roomOrFlatOrHouse == 'room'">Room
                        </option>
                    </select>
                </span>
            </div>
    </div>
    </div>
</header>

<section class="container" style="min-width: -webkit-fill-available;">
    <div class="row" style="min-height: 500px;">
        <div class="col-3" style="background-color: #f8f9fa;">
            <div class="filter-box">
				<form>
					<div class="form-wrapper">
						<label class="d-block label-text">Budget</label>
						<div class="range-slider-wrap two-slider">
							<div class="slidecontainer">
								<label class="range-slider-label left">&#0163; {{minRange}}</label>
								<input type="range" [min]="minRange" [max]="maxRange/2" name="minRangeInput"
									[(ngModel)]="selectedMinRange" class="range-slider" id="firstRnage"
									(change)="onChangeRange($event)" [title]="selectedMinRange">
								<div class="range-input-wrap">
									<label class="range-min-max left d-block">Min</label>
									<label class="range-slider-input-label">&#0163;</label>
									<input type="text" [disabled]="true" class="range-input-box" name='inputMinPrice'
										[(ngModel)]="inputMinPrice" (change)="onChangePrice()">
								</div>
							</div>
							<div class="slidecontainer">
								<label class="range-slider-label right">&#0163; {{maxRange}}</label>
								<input type="range" [min]="maxRange/2" [max]="maxRange" name="maxRangeInput"
									[(ngModel)]="selectedMaxRange" class="range-slider" id="lastRnage"
									(change)="onChangeRange($event)" [title]="selectedMaxRange">
								<div class="range-input-wrap">
									<label class="range-min-max right d-block text-right">Max</label>
									<label class="range-slider-input-label">&#0163;</label>
									<input type="text" [disabled]="true" class="range-input-box" name='inputMaxPrice'
										[(ngModel)]="inputMaxPrice" (change)="onChangePrice()">
								</div>
							</div>
						</div>
					</div>
					<div class="bedroom-main">
						<label class="d-block label-text">Apartment</label>
						<app-dropdown (updateFilter)="dropDownEventHandler($event)" [optionsList]="bedroomOptionsList">
						</app-dropdown>
					</div>
					<div class="form-wrapper mt-4">
						<label class="d-block label-text">Amenities</label>
						<!-- <input class="form-control form-control-sm" type="text" placeholder="Search..."
							(keyup)='updateFilter($event)'> -->
						<ng-select name="search-keywords" 
                            [items]="['kitchen', 'AC', 'Heater', 'Washing Machine', 'Hair Dryer']" 
                            [addTag]=true autofocus [multiple]=true
							addTagText="Add keyword" notFoundText="Type keyword" placeholder="Search...."
							[(ngModel)]="keywords" (add)="addKeyword()" (remove)="removeKeyword($event)"
							(clear)="multiSelectClear()">
						</ng-select>
					</div>
				</form>
			</div>
        </div>
        <div class="col-6">
            <div class="tab-content">
                <div id="card" [ngClass]="{'tab-pane':true, 'active': activeTab == 0}">
                    <div class="card-tab-wrap">
                        <table>
                            <tr *ngFor="let item of tempData | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <div class="House" style="width: 600px;">
                                    <div class="House-images-container">
                                        
                                        <div class="img-gallery">
											<div class="carousel slide search-carousel" data-ride="carousel">
												<app-carousel [images]="item.images"></app-carousel>
											</div>
										</div>
                                    </div>
                                        <div class="house-text">
                                            <a [routerLink]="['/prop-details', item._id]"
                                                target="_blank"
                                               [queryParams]="{
                                                               bedRooms: bedroomsCount,
                                                               amenities: keywords,
                                                               searchQuery: searchQuery,
                                                               pageNumber:page,
                                                               selectedMinRange: selectedMinRange,
                                                               selectedMaxRange: selectedMaxRange,
                                                               latitude:item.latitude,
                                                               longtitude:item.longitude,
                                                               property_type:roomOrFlatOrHouse
                                                            }">
                                                <h1>{{item.property_title}}</h1>
                                                <h4>Location</h4>
                                                <p>{{item.address}}</p>
                                                <h4>Nearest Station</h4>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{{item.landmarks}}</p>
                                                    <span>{{item.price_currency ? item.price_currency : '£'}}{{item.price}}</span>
                                                  </div>
                                            </a>
                                        </div>
                                </div>
                            </tr>
                        </table>
                        <div *ngIf="tempData.length==0 && !dataLoading" class="no-result-container">
                            <div>
                                <h6>
                                    No Result Found
                                </h6>
                            </div>
                        </div>
                        <ng-container *ngIf="tempData.length">
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="tempData.length"
                                [boundaryLinks]="true" [maxSize]="10" [rotate]="true">
                            </ngb-pagination>
                        </ng-container>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-3">
            <ng-container>
                <div id="map-id" [ngClass]="{'tab-pane':true, 'active': activeTab == 1}">
                    <div id="map" class="map-container ">
                        <!-- Map -->
                        <agm-map [latitude]="lat" [longitude]="lng" [styles]="styles"
                         [zoom]="zoom" [disableDefaultUI]="false"
                            (mapClick)="mapClicked($event)">
    
                            <agm-marker *ngFor="let m of markers; let i = index"
                                (markerClick)="clickedMarker(m.label, i, content); open(mymodal)" [latitude]="m.lat" [longitude]="m.lng"
                                [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)"
                                [iconUrl]="icon">

                            </agm-marker>
    
                            <agm-circle [latitude]="lat + 0.3" [longitude]="lng" [radius]="5000" [fillColor]="'red'"
                                [circleDraggable]="true" [editable]="true">
                            </agm-circle>
    
                        </agm-map>
                    </div>
                </div>
            </ng-container>
        </div>
      </div>
</section>
<ngx-spinner size="medium" color="#fff" type="ball-spin-clockwise" [fullScreen]="false">
	<p style="color: white"> Loading... </p>
</ngx-spinner>

<ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Property Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

        <div class="modal-body">
            <div class="house-model-image">
                <img src="{{selectedMapitem.images[0]}}" alt="house">
                <div class="d-flex justify-content-between align-items-center heading">
                    
                    <span>Price {{selectedMapitem.price}}</span>
                </div>
                <div class="align-items-center row">
                    <div class="col-md-8">
                        
                    </div>
                    <div class="justify-content-md-end d-flex justify-content-sm-start col-md-4">
                        <a (click)="urlClick(selectedMapitem.detail_page_url)"><button>View Details</button></a>
                    </div>
                </div>
            </div>
            <div class="house-modal-text">
                <div class="align-items-center justify-content-between row">
                    <div class="col-md-5">
                        <h4>Location</h4>
                        <p>{{selectedMapitem.address}}</p>
                    </div>
                    <div class="col-md-5">
                        <h4>Nearest Station</h4>
                        <p></p>
                    </div>
                </div>
        <div class="modal-features">
            <h4>Features &amp; Description</h4>
            <ul class="row  justify-content-between"></ul>
        </div></div></div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
  </ng-template>




