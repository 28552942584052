export const Logo = {
    imageURL: 'assets/images/Spook-it-logo.svg'
}

export const Category = [
    {
        name: 'house',
        label: 'house',
        iconName: 'home',
        imageURL: 'assets/images/home-icon.svg',
        customStyle: {}
    },
    {
        name: 'flat',
        label: 'flat',
        iconName: 'home',
        imageURL: 'assets/images/flat-icon.svg',
        customStyle: {}
    },
    {
        name: 'room',
        label: 'room',
        iconName: 'home',
        imageURL: 'assets/images/roomshare-icon.svg',
        customStyle: {}
    }
]
