<ngb-carousel *ngIf="images">
    <ng-template ngbSlide *ngFor="let image of images">
        <div class="picsum-img-wrapper">
            <img [src]="image" alt="Random first slide" class="img-custom">
        </div>
        <div class="carousel-caption">
            <!-- <h3>10 seconds between slides...</h3>
            <p>This carousel uses customized default values.</p> -->
        </div>
    </ng-template>
</ngb-carousel>