import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainSearchRouteModule } from './main-search-route.module';
import { MainSearchComponent } from './main-search.component';
import { CategoryModule } from 'src/app/shared/category/category.module';
import { FormsModule } from '@angular/forms';
import { SearchBoxModule } from 'src/app/shared/search-box/search-box.module';

@NgModule({
  declarations: [MainSearchComponent],
  exports: [MainSearchComponent],
  imports: [
    CommonModule,
    MainSearchRouteModule, FormsModule,
    CategoryModule, SearchBoxModule
  ]
})
export class MainSearchModule { }
