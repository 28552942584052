import { Component, OnInit, Input } from '@angular/core';
// import { Logo } from './category.model';

@Component({
  selector: 'app-category-button',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryButonComponent implements OnInit {

  constructor() { }

  @Input() item: any;
  ngOnInit(): void {
  }

}
