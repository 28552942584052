import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { DropdownComponent } from './dropdown.component';

@NgModule({
    imports: [CommonModule, NgbModule, FormsModule],
    declarations: [DropdownComponent],
    exports: [DropdownComponent],
    bootstrap: [DropdownComponent]
})
export class DropdownComponentModule { }
