export const Logo = {
    imageURL: 'assets/images/Spook-it-logo.svg'
}

export const BedroomOptionsList = [
    {
        id: 'Select',
        name: 'Select',
        value: 'Select',
        selected: true
    }, {
        id: 1,
        name: 1,
        value: 1,
        selected: false
    },
    {
        id: 2,
        name: 2,
        value: 2,
        selected: false

    },
    {
        id: 3,
        name: 3,
        value: 3,
        selected: false

    },
    {
        id: 4,
        name: 4,
        value: 4,
        selected: false

    },
    {
        id: 5,
        name: 5,
        value: 5,
        selected: false

    },
    {
        id: 6,
        name: 6,
        value: 6,
        selected: false

    },
    {
        id: 7,
        name: 7,
        value: 7,
        selected: false

    },
    {
        id: 8,
        name: 8,
        value: 8,
        selected: false

    },
    {
        id: 9,
        name: 9,
        value: 9,
        selected: false

    },
    {
        id: 10,
        name: 10,
        value: 10,
        selected: false

    }];