import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';

const delay = '15';
const numberOfRecord = '50';

@Injectable({ providedIn: 'root' })
export class SearchService {
  baseUrl: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  search(searchQuery: any): Observable<any> {
    console.log(JSON.stringify(searchQuery));
    return this.http.get(
      `${this.baseUrl}/scrap/api/v1/search?` + searchQuery + '&delay=' + delay + '&numberOfRecord=' + numberOfRecord
    );
    // return this.http.get('assets/json/search-result-test.json')
  }

  getIpAddress() {
    return this.http.get(`${this.baseUrl}/ip-address`);
  }

  getIpCliente(): Observable<string> {
    return this.http.get('http://api.ipify.org/?format=json').pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  //   getIpCliente(): any {
  //     this.http.get<any>('https://geolocation-db.com/json/').pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       }),
  //       tap((response) => {
  //         console.log(response.IPv4);
  //       })
  //     );
  //   }

  getIP() {
    this.http.get<any>('https://geolocation-db.com/json/').pipe(
      catchError((err) => {
        return throwError(err);
      }),
      tap((response) => {
        console.log(response.IPv4);
      })
    );
  }

  getDetails(id: string, latitude: string, longtitude: string):Observable<any> {
    return this.http.post(
      `https://spookit.co.uk/scrap/api/v1/search`,
      {'id': id}
    );
  }
}
