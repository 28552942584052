import { Component, OnInit } from '@angular/core';
// import { Logo } from './search-result.model';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { SearchService } from '../../core/search/search-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataUtils } from 'src/app/common/util';
import { BedroomOptionsList } from './search-result.model';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-search-result',
	templateUrl: './search2.html',
	styleUrls: ['./search-result.component.scss', './extra.css'],
})
export class SearchResultComponent implements OnInit {
	searchQuery = '';
	searchData = [];
	tempData = [];
	page: number = 1;
	pageSize = 10;
	lengthOfRecord: any = 1;

	showSidebar: boolean = true;
	options: any = ['Garage', 'Ensuite', 'Garden'];
	selectedOptions: any='Select';
	minRange: any = '1000';
	selectedMinRange: any;
	maxRange: any = '7000';
	selectedMaxRange: any;
	inputMinPrice: string = '0';
	inputMaxPrice: string = '0';
	activeTab: number = 0;
	dataLoading: boolean = false;

	rentOrBuy: string = 'rent';
	roomOrFlatOrHouse: string = 'house';
	keywords: any = [];

	// map
	styles = [
		{
		  "featureType": "administrative.land_parcel",
		  "elementType": "labels",
		  "stylers": [
			{
			  "visibility": "off"
			}
		  ]
		},
		{
		  "featureType": "poi",
		  "elementType": "labels.text",
		  "stylers": [
			{
			  "visibility": "off"
			}
		  ]
		},
		{
		  "featureType": "road.local",
		  "elementType": "labels",
		  "stylers": [
			{
			  "visibility": "off"
			}
		  ]
		}
	  ]

	center: google.maps.LatLngLiteral;
	mapOptions: google.maps.MapOptions = {
		mapTypeId: 'hybrid',
		zoomControl: false,
		scrollwheel: false,
		disableDoubleClickZoom: true,
		maxZoom: 15,
		minZoom: 8,
	};

	minRangeRent: any = 0;
	selectedminRangeRent: any;
	maxRangeRent: any = 10000;
	selectedaxRangeRent: any;

	minRangeBuy: any = 0;
	selectedminRangeBuy: any;
	maxRangeBuy: any = 1000000;
	selectedaxRangeBuy: any;

	bedroomOptionsList = [...BedroomOptionsList];
	bedroomsCount: any;

	closeResult: string;
	
	// Map
	// google maps zoom level
	zoom: number = 15;
	// initial center position for the map
	lat: number = 51.538067;
	lng: number = -0.093949;

	icon = {
		url: 'assets/images/Home_icon.svg',
		scaledSize: {
			width: 70,
			height: 70
		  },
	  };

	ipAddress: any = '';

	constructor(
		private searchService: SearchService,
		private modalService: NgbModal,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private router: Router
	) { }

	ngOnInit(): void {
		const amenities = this.route.snapshot.queryParamMap.getAll('amenities') || [];
		amenities.forEach(key => this.keywords.push(key));

		const query = this.route.snapshot.queryParamMap.get('searchQuery') || '';
		this.searchQuery = query;

		// Get page number
		this.page= parseInt(this.route.snapshot.queryParamMap.get('pageNumber')) || 1;
		// this.page = pageNum

		this.roomOrFlatOrHouse = this.route.snapshot.queryParamMap.get('property_type') || 'house';
		console.log("roomOrFlatOrHouse", this.roomOrFlatOrHouse)
		this.selectedMinRange= parseInt(this.route.snapshot.queryParamMap.get('selectedMinRange')) || 0;
		this.selectedMaxRange= parseInt(this.route.snapshot.queryParamMap.get('selectedMaxRange')) || 7000;
		this.inputMinPrice = this.selectedMinRange;
		this.inputMaxPrice = this.selectedMaxRange;
		
		
		// // Check data in local storage
		// const localD = this.getLocalItems()

		// if (localD) {
		// 	this.searchData = localD;
		// 	this.searchData.forEach((element) => {
		// 		const url = this.parseUri(element.data_url);
		// 		element.data_url = url.domain;
		// 		element.completeURL = url.source;
		// 	});
		// 	this.tempData = this.searchData;
		// 	console.log("temp data!@@#", this.tempData)
		// 	this.filterData()
		// 	this.createMapData(localD)
		// }
		 
		this.route.queryParams.subscribe((params) => {
			this.spinner.show();
			
			this.searchQuery = params.searchQuery;
			this.ipAddress = params.ipAddress;
			const searchQuery = this.createSearchRq();
			
			this.searchService.search(searchQuery).subscribe(
				(result) => {
					this.dataLoading = false;
					this.spinner.hide();
					if (result.status === 'Success') {
						if (result.data) {
							console.log("response", result)
							this.searchData = result.data;
							this.searchData.forEach((element) => {
								const url = this.parseUri(element.data_url);
								element.data_url = url.domain;
								element.completeURL = url.source;
							});
							this.lengthOfRecord = result.lengthOfRecord;
							// this.minMaxValue();
							this.setMinMaxValue(result);
							this.tempData = [...this.searchData];
							// console.log("temp", this.tempData)
							this.filterData()
							// TODO
							this.createMapData(this.searchData);
							this.setLocalItems(this.searchData, params);
						}
				}},
				error => {
					this.spinner.hide();
					console.log("some error occured", error);
				},
			);
		});
		
		navigator.geolocation.getCurrentPosition((position) => {
			console.log("pasition!@#", position.coords)
			this.center = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			};
		});
	}

	urlClick(urlVal) {
		window.open(urlVal, '_blank');
	}

	searchQueryCall(query) {
		this.searchQuery = query;
		this.spinner.show();
		const searchQuery = this.createSearchRq();
		this.tempData = [];
		this.searchService.search(searchQuery).subscribe((response) => {
			this.dataLoading = true;
			this.spinner.hide();
			// if (response.status === 'Success') {
			if (response.data) {
				this.searchData = response.data;
				this.searchData.forEach((element) => {
					const url = this.parseUri(element.data_url);
					element.data_url = url.domain;
					element.completeURL = url.source;
				});
				this.lengthOfRecord = 1;
				this.lengthOfRecord = response.lengthOfRecord;
				// this.minMaxValue();
				this.setMinMaxValue(response);
				this.tempData = [...this.searchData];
				this.filterData();
			}
		});
	}

	parseUri(sourceUri) {
		let uriPartNames = [
			'source',
			'protocol',
			'authority',
			'domain',
			'port',
			'path',
			'directoryPath',
			'fileName',
			'query',
			'anchor',
		];
		let uriParts = new RegExp(
			'^(?:([^:/?#.]+):)?(?://)?(([^:/?#]*)(?::(\\d*))?)((/(?:[^?#](?![^?#/]*\\.[^?#/.]+(?:[\\?#]|$)))*/?)?([^?#/]*))?(?:\\?([^#]*))?(?:#(.*))?'
		).exec(sourceUri);
		let uri: any = {};

		for (var i = 0; i < 10; i++) {
			uri[uriPartNames[i]] = uriParts[i] ? uriParts[i] : '';
		}

		/* Always end directoryPath with a trailing backslash if a path was present in the source URI
			Note that a trailing backslash is NOT automatically inserted within or appended to the "path" key */
		if (uri.directoryPath.length > 0) {
			uri.directoryPath = uri.directoryPath.replace(/\/?$/, '/');
		}

		return uri;
	}

	toggleSidebar() {
		this.showSidebar = !this.showSidebar;
	}

	extractPrice(price) {
		// return parseInt(
		// 	price.split(' ')[0].replace('£', '').replace(/^"(.*)"$/, '$1').replace(',', '')
		// );
		return Number(price.replace(/[^0-9.-]+/g, ''));
	}

	filterRentBuy(key) {
		this.rentOrBuy = key;
		this.searchQueryCall(this.searchQuery);

		// this.showMinMaxOnType();
		// this.filterData()
	}

	filterPropertyType(key) {
		this.roomOrFlatOrHouse = key;
		this.searchQueryCall(this.searchQuery);
		// this.filterData()
	}

	setMinMaxValue(data) {
		if (data.minPrice !== null && data.maxPrice !== null) {
			this.minRange = Number(data.minPrice);
			this.maxRange = Number(data.maxPrice);
			this.selectedMinRange = this.minRange;
			this.selectedMaxRange = this.maxRange;
			this.inputMinPrice = this.minRange;
			this.inputMaxPrice = this.maxRange;
		}
	}

	minMaxValue() {
		let minP = 0;
		this.searchData.forEach((element) => {
			const arr = element.price.split('-');
			const minprice = arr[0] ? Number(arr[0].replace(/\D/g, '')) : 0;
			const priceValue = arr[0] ? Number(arr[0].replace(/\D/g, '')) : '';
			element.priceValue = priceValue;
			if (element.property_for.toLowerCase().includes('sale')) {
				if (minprice < minP) {
					minP = minprice;
					this.minRangeBuy = minprice;
				}
			} else if (element.property_for.toLowerCase().includes('rent')) {
				if (minprice < minP) {
					minP = minprice;
					this.minRangeRent = minprice;
				}
			} else {
				if (minprice < minP) {
					minP = minprice;
				}
			}
		});

		//let maxP = this.minRange;
		let maxRentPrice = this.minRange;
		let maxBuyPrice = this.minRange;
		this.searchData.forEach((element) => {
			const arr = element.price.split('-');
			const maxprice = arr[0] ? Number(arr[0].replace(/\D/g, '')) : 0;
			// if (maxprice > maxP) {
			// 	maxP = maxprice;
			// };
			if (element.property_for.toLowerCase().includes('sale')) {
				if (maxprice > maxBuyPrice) {
					maxBuyPrice = maxprice;
					this.maxRangeBuy = maxprice;
				}
			} else if (element.property_for.toLowerCase().includes('rent')) {
				if (maxprice > maxRentPrice) {
					maxRentPrice = maxprice;
					this.maxRangeRent = maxprice;
				}
			}
		});
		// this.minRange = minP;
		// this.maxRange = maxP;
		this.showMinMaxOnType();
	}

	showMinMaxOnType() {
		if (this.rentOrBuy === 'buy') {
			this.minRange = this.minRangeBuy;
			this.maxRange = this.maxRangeBuy;
			this.selectedMinRange = this.minRangeBuy;
			this.selectedMaxRange = this.maxRangeBuy;
			this.inputMinPrice = this.minRangeBuy;
			this.inputMaxPrice = this.maxRangeBuy;
		}
		if (this.rentOrBuy === 'rent') {
			this.minRange = this.minRangeRent;
			this.maxRange = this.maxRangeRent;
			this.selectedMinRange = this.minRangeRent;
			this.selectedMaxRange = this.maxRangeRent;
			this.inputMinPrice = this.minRangeRent;
			this.inputMaxPrice = this.maxRangeRent;
		}
	}



	createFilterCriterias() {
		this.selectedMaxRange;
	}

	filterData() {
		this.tempData = this.searchData;
		console.log("property type", this.roomOrFlatOrHouse);
		console.log("price", this.selectedMinRange, this.selectedMaxRange)
		const filters = {
			property_type: (property_type) =>
				property_type === this.roomOrFlatOrHouse,
			property_for: (property_for) =>
				property_for === (this.rentOrBuy === 'buy' ? 'sale' : 'rent'),
			price: (price) =>
				Number(price) >= this.selectedMinRange &&
				price <= this.selectedMaxRange,
			property_description: (property_description) =>
				this.keywords.length === 0
					? true
					: this.findInString(this.keywords, property_description),
			bedrooms: (bedrooms) =>
				this.bedroomsCount === '' ? true : this.bedRoomValidation(bedrooms),
		};
		const data = DataUtils.filterArray(this.tempData, filters);
		console.log('data', data)
		this.tempData = data;
	}

	findInString(arr, str) {
		let flag = true;
		const lowerStr = str.toLowerCase();
		for (let elem of arr) {
			if (str.toLowerCase().indexOf(elem) === -1) {
				flag = false;
				break;
			}
		}
		return flag;
	}

	bedRoomValidation(bedroom) {
		if (!bedroom && this.bedroomsCount !== 'Select') {
			return false;
		} else if (!bedroom && this.bedroomsCount === 'Select') {
			return true;
		} else if (bedroom && this.bedroomsCount === 'Select') {
			return true;
		} else if (Number(bedroom) == Number(this.bedroomsCount)) {
			return true;
		} else {
			return false;
		}
	}

	onChangeRange(event) {
		this.inputMinPrice = this.selectedMinRange;
		this.inputMaxPrice = this.selectedMaxRange;
		this.filterData();
	}

	onChangePrice() {
		if (!this.inputMinPrice || !this.inputMaxPrice) {
			return;
		}
		if (parseInt(this.inputMaxPrice) < parseInt(this.inputMinPrice)) {
			this.inputMaxPrice = this.inputMinPrice;
		}
		this.filterData();
	}

	isEquivalent(a, b) {
		// Create arrays of property names
		var aProps = Object.getOwnPropertyNames(a);
		var bProps = Object.getOwnPropertyNames(b);

		if (aProps.length != bProps.length) {
			return false;
		}

		for (var i = 0; i < aProps.length; i++) {
			var propName = aProps[i];
			if (a[propName] !== b[propName]) {
				return false;
			}
		}
		return true;
	}

	addKeyword() {
		this.filterData();
	}

	removeKeyword(keyword: any) {
		this.filterData();
	}
	multiSelectClear() {
		this.filterData();
	}

	onChangeTab = (tabIndex: number) => {
		this.activeTab = tabIndex;
		if (tabIndex === 1) {
			this.createMapData(this.tempData);
		}
	};

	zoomIn() {
		if (this.zoom < this.options.maxZoom) this.zoom++;
	}

	zoomOut() {
		if (this.zoom > this.options.minZoom) this.zoom--;
	}

	dropDownEventHandler(event) {
		this.bedroomsCount = event.id;
		this.filterData();
	}

	onPageChange(evt) {
		this.searchQueryCall(this.searchQuery);
	}
	createSearchRq() {
		let obj: any;
		let roomOrFlatOrHouse = [];
		roomOrFlatOrHouse.push(
			this.roomOrFlatOrHouse ? this.roomOrFlatOrHouse : ''
		);
		let rentOrBuy = [];
		const saleOrRent = this.rentOrBuy === 'buy' ? 'sale' : 'rent';
		rentOrBuy.push(saleOrRent ? saleOrRent : '');
		let keywords = [];
		keywords = this.keywords ? this.keywords : [];

		obj =
			'search=' +
			this.searchQuery +
			'&property_type=' +
			roomOrFlatOrHouse +
			'&property_for=' +
			rentOrBuy +
			'&property_description=' +
			keywords
		return obj;
	}

	//Modal

	openLg(content) {
		this.modalService.open(content, { size: 'lg' });
	}

	// Map
	clickedMarker(label: string, index: number, content: any) {
		console.log(`clicked the marker!@#: ${label || index}`);
		this.selectedMapitem = this.tempData[index];
		// this.open()
		//this.openLg(content);
		// const btn = document.getElementById('modalOpenBtn');
		// btn.click();
	}

	mapClicked(event: MouseEvent) {
		// this.markers.push({
		// 	lat: $event.coords.lat,
		// 	lng: $event.coords.lng,
		// 	draggable: true
		// });
		// selectedMapitem
		console.log(event);
	}

	markerDragEnd(m: marker, $event: MouseEvent) {
		console.log('dragEnd', m, $event);
	}

	markers: marker[] = [];

	selectedMapitem: any;

	createMapData(data) {
		this.markers = [];
		if (data && data.length > 0) {
			data.slice(0,20).forEach((element) => {
				const obj: any = {};
				// console.log(element.latitude, element.longitude, typeof(element.latitude), typeof(element.longitude))
				obj.lat = parseFloat(element.latitude);
				obj.lng = parseFloat(element.longitude);
				obj.label = {text:String(element.price), color: '#003b6a'};
				obj.draggable = false;
				this.markers.push(obj)
			});
		}
		if (data && data.length > 0) {
			this.lat = parseFloat(data[0].latitude) ? parseFloat(data[0].latitude): 51.551011
			this.lng = parseFloat(data[0].longitude) ? parseFloat(data[0].longitude): 0.052911
		}
	}

	getLocalItems() {
		const localS = JSON.parse(localStorage.getItem('spookitData'))
		// console.log("local query", localS['params']['searchQuery'], "local page", localS['params']["pageNumber"])
		if (localS) {
			const timeStamp = localS['timestamp'];
			if (
				Date.now() - timeStamp < 600000 
				&& localS['params']['searchQuery'] == this.searchQuery 
				&& parseInt(localS['params']['pageNumber']) == this.page
			) return localS['data']
			return
		}
		else {
			console.log("not there")
			return;
		}
	}

	setLocalItems(data, params) {
		const dataL = {'data': data, 'params': params, 'timestamp': Date.now()}
		localStorage.setItem('spookitData', JSON.stringify(dataL))
	}

	propTypeChange(e) {
		this.roomOrFlatOrHouse = e;
		this.router.navigate(['search-result'], {
			queryParams:
			{ searchQuery: this.searchQuery, 
			  pageNumber: 1, 
			  property_type: e,
			  numberOfRecord:10},
		  });

	  }

	  open(content:any) {
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		  this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	  }
	  
	  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		  return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		  return 'by clicking on a backdrop';
		} else {
		  return  `with: ${reason}`;
		}
	  }
}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}
