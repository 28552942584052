<!-- <select class="form-control" [(ngModel)]="selectedOption" (change)="emitSelectedList($event.target.selectedIndex)">
    <option *ngFor="let option of optionsList ">{{option.name}}</option>
</select> -->

    <!-- <button id="" value="1RK" name="appartment1Rk"
      (click)="emitSelectedList('1rk')" 
      [ngClass]="{'bedBtn':true}">1 RK</button> -->
    <button id="" value="1" name="appartment1BHK" 
     (click)="emitSelectedList(1)" 
     [ngClass]="{'bedBtn':true}">1 BHK</button>
    <button id="" value="2" name="appartment2BHK" 
     (click)="emitSelectedList(2)" 
     [ngClass]="{'bedBtn':true}">2 BHK</button>
    <button id="" value="3" name="appartment3BHK" 
      (click)="emitSelectedList(3)" 
      [ngClass]="{'bedBtn':true}">3 BHK</button>
    <button id="" value="4" name="appartment4BHK" 
     (click)="emitSelectedList(4)" 
     [ngClass]="{'bedBtn':true}">4 BHK</button>
    <button id="" value="5" name="appartment4PlusBHK" 
     (click)="emitSelectedList(5)" 
     [ngClass]="{'bedBtn':true}">5 BHK</button>
    <button id="" value="6" name="appartment4PlusBHK" 
     (click)="emitSelectedList(6)" 
     [ngClass]="{'bedBtn':true}">6 BHK</button>
