import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.css'],
})
export class DropdownComponent {

    @Input() optionsList: any;
    @Output() updateFilter = new EventEmitter<any>();

    searchQuery = ''
    selectedOption: any;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        // this.listFilter = this.route.snapshot.queryParamMap.get('filterBy') || '';
        const routeOption = this.route.snapshot.queryParamMap.get('bedRooms') || '';

        const selectedObj = this.optionsList.filter(element => element.value === parseInt(routeOption));
        this.selectedOption = selectedObj.length > 0 ? selectedObj[0].value : 0;
        this.emitSelectedList(this.selectedOption)
    }

    emitSelectedList(selectedItem) {
        console.log('optionList', this.optionsList);
        console.log('selectedItem', selectedItem);
        this.updateFilter.emit(this.optionsList[selectedItem]);
    }

}
