import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'src/app/shared/carousel/carousel.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { NgxSpinnerModule } from "ngx-spinner";
import { GoogleMapsModule } from '@angular/google-maps';

import { NgSelectModule } from '@ng-select/ng-select';


import { SearchBoxModule } from 'src/app/shared/search-box/search-box.module';
import { SearchResultRouteModule } from './search-result-route.module';
import { SearchResultComponent } from './search-result.component';

import { DropdownComponentModule } from 'src/app/shared/dropdown/dropdown.component.module';

import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [SearchResultComponent],
  exports: [SearchResultComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxSpinnerModule,
    CarouselModule,
    GoogleMapsModule,
    NgSelectModule,
    DropdownComponentModule,

    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      // apiKey: 'AIzaSyCntqqoVossnBgmImNCle-kDjAPaYrnXEA'
      apiKey: 'AIzaSyCntqqoVossnBgmImNCle-kDjAPaYrnXEA'
    }),


    SearchResultRouteModule,
    SearchBoxModule,

  ]
})
export class SearchResultModule { }
