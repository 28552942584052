import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PropDetailsComponent } from './views/prop-details/prop-details.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: './views/main-search/main-search.module#MainSearchModule'
  },
  {
    path: 'search-result',
    loadChildren: './views/search-result/search-result.module#SearchResultModule'
  },
  {
    path: 'prop-details/:id',
    component: PropDetailsComponent
  },

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
