import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Input() initialQuery = '';
  @Output() searchQueryCallBck = new EventEmitter<any>();
  searchQuery = ''

  constructor() {
  }

  ngOnInit() {
    this.searchQuery = this.initialQuery;
  }

  searchData() {
    this.searchQueryCallBck.emit(this.searchQuery);
  }
}
